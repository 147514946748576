.react-datepicker-popper {
    z-index: 1030 !important;
}

// .react-datepicker-popper[data-placement^=bottom] {
//     padding-top: 0;
// }

.react-datepicker {
    font-size: 13px;
    font-family: inherit;
    border: none;
    border-radius: 6px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
}

// .react-datepicker-popper[data-placement^='bottom'] {
//     margin-top: 8px;
// }

// .react-datepicker-popper[data-placement^='top'] {
//     margin-bottom: 4px;
// }

.react-datepicker__header {
    border-bottom: 0;
    background: #fff !important;
    // padding-top: 0;
}

// .react-datepicker__triangle {
//     display: none;
// }

.react-datepicker__navigation {
    // width: 32px;
    height: 32px;
    // line-height: 32px;
    top: 10px !important;

    // border: none;
    // @extend .gc-icon;
    // @extend .clickable;
}

// .react-datepicker__navigation span:before {
//     display: none;
// }

// .react-datepicker__navigation:focus {
//     background-color: transparent;
// }

// .react-datepicker__navigation:active {
//     box-shadow: none;
// }

// .react-datepicker__navigation--previous {
//     left: 10px;
//     // @extend .gc-icon--left-circle-orange.gc-icon--large;
// }

// .react-datepicker__navigation--next {
//     right: 10px;
//     // @extend .gc-icon--right-circle-orange.gc-icon--large;
// }

.react-datepicker__today-button {
    // @extend .gc-button;
    margin: 0 12px 12px 12px;
    border-top: none !important;
    border-radius: 4px;
}

.react-datepicker__day-name {
    // color: #424242;
    font-weight: 600;
    // width: 32px;
    // line-height: 32px;
    margin: 0 4px;
}

.react-datepicker__current-month {
    // color: $textColor;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    padding: 16px 20px 12px;
    user-select: none;
    cursor: default;
}

.react-datepicker__month {
    margin: 0 12px 12px 12px;
}

.react-datepicker__month-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.react-datepicker__month-text {
    padding: 5px;
    width: 5rem !important;
}

.react-datepicker__month--selected {
    background-color: #459E51 !important;
}

.react-datepicker__month-text--keyboard-selected {
    background-color: #459E51 !important;
    // color: #000 !important;
}

.react-datepicker__day {
    // color: $textColor;
    font-size: 13px;
    font-weight: 400;
    width: 32px !important;
    height: 32px;
    line-height: 32px;
    margin: 0 0px;
    background-color: #fff;
}

.react-datepicker__day:hover {
    border-radius: 100%;
}

.react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
    border-radius: 100%;
    background-color: transparent;
    // @extend .clickable;
}

.react-datepicker__day--disabled {
    color: #424242;
}

.react-datepicker__day--today {
    font-weight: 700;
    border-radius: 100%;
    background-color: #eeeeee !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    color: #fff;
    font-weight: 600;
    background-color: #459E51 !important;
    border-radius: 50% !important;
}

.react-datepicker__day--selected:hover {
    background-color: #459E51;
}
