

.sticky-top {
    transition: all 0.15s ease-in-out;
}

.main-navbar {
    height: 100%;
    background-color: #00dc78;
    color: black;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
}

.header-link {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-decoration: none;

    background-color: transparent;
    color: #000;

    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0px 20px;

    font-weight: 600;
    font-size: 1.125rem;
}

.header-link-active {

    background-color: #fff !important;
    color: #000 !important;
}

.header-link:hover {
    color: #000;
    background-color: #FDBE12;
}

.header-link-active:hover {
    background-color: #fff;
    color: #222;
}

.header-avatar {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;

    background-color: #00dc78;
    color: #000;
    align-items: center;

    border-left: 1px solid #FFFFFF66;
}

.header-avatar-title {
    font-size: 14px;
    font-weight: 700;
    text-align: end;
    line-height: 20px;

    max-width: 125px;
    max-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.header-avatar-company {
    font-size: 14px;
    font-weight: 700;
    font-style: italic;
    text-align: end;
    line-height: 20px;

    max-width: 125px;
    max-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.header-avatar:hover {
    color: #000;
}

.header-avatar-subtitle {
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
}

.header-avatar-image {
    background-color: white;
}

.header-avatar-active {
    color: #000;
}

.header-avatar-company.header-avatar-active {
    color: #262524;
}

.regular-initials {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
}

.small-initials {
    font-size: 14px;
}

.submenu-container {
    transition: all 0.15s ease-in-out;
    position: sticky;
    top: 60px;
    background-color: #FFFFFFEE;
    z-index: 1010;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.submenu {
    padding: 1rem;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: #5C5C5C;

    border-bottom: transparent;
    border-bottom-width: 4px;
    border-bottom-style: solid;
}

.submenu.active {
    color: #181818;

    border-bottom: #00dc78;
    border-bottom-width: 4px;
    border-bottom-style: solid;
}

.submenu:hover {
    color: #181818;
    background-color: #00000005;
}

.brand {
    background: linear-gradient(
        to right,
        #F2F2F2 50%,
        #002B7F 50%,
        #002B7F 66%,
        #FCD116 66%,
        #FCD116 84% ,
        #CE1126 84%,
        #CE1126 100%
    ) !important;
    background-size: 200% 100% !important;
    transition: background-position 1s !important;
    /* color: transparent !important; */
    /* background-clip: text !important; */
    /* -webkit-background-clip: text !important; */
}

.brand:hover {
    background-position: -100% 0 !important;
}

.footer-text {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.footer-link {
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;

    background-color: transparent;
    color: #000;
}

.footer-link:hover {
    color: #222;
    text-decoration: underline;
}
