@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/mixins/buttons';

$primary: #00dc78;
$secondary: #262524;
// $warning: #FFBE0B;
$light: #f5f5fa;
$dark: #45413f;
// $info: #2D69FF;

$returo: #00dc78;
$mandarin: #FFBE0B;

$enable-shadows: true;
$enable-gradients: true;

$min-contrast-ratio: 2.2;

$navbar-padding-y: 0px;
$navbar-padding-x: 1rem;

$nav-link-color: null;
$nav-link-hover-color: null;

$table-striped-bg-factor: 0.02;
// $table-th-font-weight: 600;

$font-size-base: 1rem;
$small-font-size: 0.8em;

$form-label-font-size: 16px;
$form-label-color:#383432CC;

// $input-padding-y: 1rem; //have doubts
$input-font-size: 1rem;

// $input-font-size-lg: 1rem;
// $input-padding-y-lg: 0.5rem;
// $input-height-lg: 1.25rem;

$form-file-button-color: black;
$form-file-button-bg: white;
$form-file-button-hover-bg: #459E5132;

$btn-font-weight: 500;
$btn-font-size: 16px;
$btn-border-radius: 6px;
// $btn-link-color: #459E51;

// $navbar-color: #00dc78;
$navbar-padding-x: 0px;
// $navbar-padding-y: 1rem;

@import '../../node_modules/bootstrap/scss/variables';

$custom-colors: (
    'returo': $returo,
    'orange': $mandarin
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$popover-border-color: var(--bs-success);
$popover-header-bg:    var(--bs-success);
$popover-header-color: var(--bs-white);

@import '../../node_modules/bootstrap/scss/bootstrap.scss';

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @if $color == 'returo' {
            @include button-variant(
            $value,
            $value,
            $hover-background: $mandarin,
            $hover-border: $mandarin,
            $active-background: shade-color($mandarin, $btn-active-bg-shade-amount),
            $active-border: shade-color($mandarin, $btn-active-border-shade-amount),
            $active-color: #000
            );
        } @else {

        }
    }
}
