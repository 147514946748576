@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.App {
  /* width: 100%; */
  min-height: calc(100vh - 60px);
}


.nounderline { text-decoration: none !important; }

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.relative { position: relative; }

/* .noclickable { pointer-events: none; } */
/* .clickable { pointer-events: auto; } */
/* .hand { cursor: pointer; } */

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* .absolute0000 {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
} */

.page-title {
  @apply ss-font-semibold ss-text-2xl ss-text-base-content;
  /* font-weight: 700; */
  /* font-size: 24px; */
  /* color: #000; */
}

.form-section {
  @apply ss-font-semibold ss-text-lg;
  /* font-weight: 700;
  font-size: 16px; */
}

@media (min-width: 767.98px) {
  .form-section {
    @apply ss-text-xl;
  }
}

.form-control.lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.form-control.md {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.form-hint {
  font-size: 16px;
  color: #727272;
}

.form-file {
  font-weight: 500 !important;
  color: black !important;
  background-color: #e9ecef !important;
}

.form-btn {
  background-color: white !important;
  border: 1px solid #ced4da !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  min-width: 38px;
}

.form-btn.md {
  min-width: 50px;
}

.form-btn.lg {
  min-width: 58px;
}

.form-btn:hover {
  background-color: #e5e5e5 !important;
}

.form-btn.is-valid {
  border: 1px solid #198754 !important;
}

.form-btn.is-invalid {
  border: 1px solid #dc3545 !important;
}

.custom-datepicker {
  display: block;
  width: 100%;
  /* height: 58px; */
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 5px;
  border: solid;
  border-width: 1px;
  border-color: rgb(204, 204, 204);
  color: black;
}

.custom-datepicker.lg {
  height: 58px;
}

.custom-datepicker.md {
  height: 50px;
}

.btn-link {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.summary-label {
  font-size: 16px;
  color: #383432CC;
  word-break: break-all;
}

.summary-value {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  overflow: auto;
  word-break: break-all;
}

.status {
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  margin: 0px 2px;
}

.status.none {
  font-weight: 500;
  color: #000;
}

.status.primary {
  background-color: #19875440;
  color: #198754;
}

.status.secondary {
  background-color: #eeeeee;
  color: #424242;
}

.status.success {
  background-color: #49e75440;
  color: #198754;
}

.status.warning {
  background-color: #FBEFC699;
  color: #98761D;
}

.status.info {
  background-color: #a9F8FE99;
  color: #2D69FF;
}

.status.danger {
  background-color: #FFECEB;
  color: #B44240;
}

.status.dark {
  background-color: #333;
  color: #FFF;
}

.centered-axis-xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
}

.time-container {
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dot-separator {
  height: 1px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-image: linear-gradient(to right, #808080 20%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 5px 1px;
  background-repeat: repeat-x;
}

.blackicon path {
  fill: #000;
}

.page-item {
  cursor: pointer;
}

.w50px {
  width: 50px;
}

.pdf-container {
  background-color: #525659;
  padding: 1rem;
  margin-bottom: 1rem;

  display: flex;
  /* flex-direction: column;
  align-items: center; */
  justify-content: center;
}

.pdf-container .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.pdf-container .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.pdf-container .react-pdf__message {
  padding: 20px;
  color: white;
}

.baloons {
  background-image: url("https://acegif.com/wp-content/uploads/balloon-3.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}


.modal-container {
  position: fixed;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  /* inset: 0; inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 1080;
  /* padding: 40px 20px 20px; */
}

.modal-container .content {
  padding: 10px;
  background-color: white;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  text-align: start;
  position: relative;
  border-radius: 10px;
}

.modal-container .full-screen {
  width: 100vw;
  height: 100vh;
  border-radius: 0px;
}

.scroll-hidden {
  overflow: hidden !important;
}

.modal-scroll-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 160px);
}

.modal-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}


@media (min-width: 767.98px) {
  .modal-step {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
}


.Toastify__toast-body {
  white-space: pre-line;
}

.list-group.list-group-striped .list-group-item:not(.active):nth-of-type(even) {
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group.list-group-striped .list-group-item:not(.active):hover {
  background-color: var(--bs-list-group-action-active-bg);
}

.table th {
  font-size: 15px;
}

.large-font {
  font-size: 32px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.font-semibold {
  @apply ss-font-semibold;
}

.font-bold {
  @apply ss-font-bold;
}

.font-medium {
  @apply ss-font-medium;
}

.italic {
  @apply ss-italic;
}


@layer components {
  .ss-btn {
      @apply ss-h-10 ss-min-h-[2.5rem];
      @apply ss-font-normal;
  }
  .ss-btn-md {
      @apply ss-h-10 ss-min-h-[2.5rem];
  }
  .ss-btn-lg {
      @apply ss-h-12 ss-min-h-[3rem];
  }
  .ss-btn-sm {
      @apply ss-h-8 ss-min-h-[2.0rem];
  }
  .ss-btn-xs {
      @apply ss-h-6 ss-min-h-[1.5rem];
  }

  .ss-btn-circle {
      @apply ss-h-10 ss-w-10 ss-rounded-full ss-p-0;
  }
  .ss-btn-circle:where(.ss-btn-xs) {
      @apply ss-h-6 ss-w-6 ss-rounded-full ss-p-0;
  }
  .ss-btn-circle:where(.ss-btn-sm) {
      @apply ss-h-8 ss-w-8 ss-rounded-full ss-p-0;
  }
  .ss-btn-circle:where(.ss-btn-md) {
      @apply ss-h-10 ss-w-10 ss-rounded-full ss-p-0;
  }
  .ss-btn-circle:where(.ss-btn-lg) {
      @apply ss-h-12 ss-w-12 ss-rounded-full ss-p-0;
  }

  .btn-scale {
      @apply ss-duration-200;
      transition-property: color, background-color, border-color, opacity, box-shadow, transform;
      @media (prefers-reduced-motion: no-preference) {
          animation: button-pop var(--animation-btn, 0.25s) ease-out;
      }

      &:active:focus {
          animation: button-pop 0s ease-out;
          transform: scale(var(--btn-focus-scale, 0.97));
      }
  }

  .ss-input,
  .ss-file-input,
  .ss-select {
      min-height: auto;
      @apply ss-border-none;
      @apply ss-h-10 ss-transition-colors;
  }

  .ss-textarea {
      min-height: auto;
      @apply ss-border-none ss-transition-colors;
  }

  .ss-input-xs,
  .ss-file-input-xs,
  .ss-select-xs {
      @apply ss-h-6;
  }

  .ss-input-sm,
  .ss-file-input-sm,
  .ss-select-sm {
      @apply ss-h-8;
  }

  .ss-input-md,
  .ss-file-input-md,
  ss-.select-md {
      @apply ss-h-10;
  }

  .ss-input-lg,
  .ss-file-input-lg,
  .ss-select-lg {
      @apply ss-h-12;
  }

  /* .input,
  .file-input,
  .select,
  .textarea {
      @apply bg-background-100;
  }

  .input:focus-within,
  .file-input:focus-within,
  .select:focus-within,
  .textarea:focus-within {
      @apply bg-background-100 outline-none;
  }

  .input:hover,
  .file-input:hover,
  .select:hover,
  .textarea:hover {
      @apply bg-background-200 outline-none;
  }

  .input:hover > .select {
      @apply bg-background-200;
  }

  .input:hover > .select:focus {
      @apply bg-background-100;
  }

  .input:focus,
  .file-input:focus,
  .select:focus,
  .textarea:focus {
      @apply bg-background-100 border-none outline-none;
  } */
}
