.AuthApp {
    min-height: calc(100vh - 60px - 63px);
}

.auth-navbar {
    height: 60px;
}

@media (min-width: 767.98px) {
    .auth-navbar {
        height: 80px;
    }

    .AuthApp {
        min-height: calc(100vh - 80px - 63px);
    }
}

.bg-auth {
    background-color: #F2F2F2;
}

.bg-auth-50 {
    background-color: #F2F2F280;
}

.auth-title {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #262524;
}

@media (min-width: 575.98px) {
    .auth-title {
        font-size: 20px;
    }
}

@media (min-width: 767.98px) {

    .auth-title {
        font-size: 32px;
    }
}


.auth-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 40px;
}

.auth-inner {
    width: min(100vw - 10px, 780px);
}

.auth-inner-container {
    border-radius: 8px;
    border: 1px solid #00000033;
    background-color: #fff;
    padding: 25px;
    position: relative;
}

.landing-container {
    width: min(100vw - 10px, 780px);
    position: relative;
}

.landing-button {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    font-weight: 600;
    font-size: 28px;
}
